import { AuthenticationContext, AdalConfig } from 'react-adal';

export const adalConfig: AdalConfig = {
    // tenant: '969d4e5e-9833-4f0d-9133-5fabb09ade99',
    // clientId: '5f20b9ea-1df6-4c64-8fbd-a99f469532d8',
    //redirectUri: 'https://msgtopdftestdelete.z21.web.core.windows.net/',
    // redirectUri: 'http://localhost:3000',
    tenant: '70dbad32-6e58-4114-88e8-84f5666158df',
    clientId: '50606cbc-f6f8-4f0b-8c18-b610960b0d22',
    redirectUri: 'https://agency-reportmanager.integritymarketing.com/',   
    cacheLocation: 'sessionStorage'
};

export const authContext = new AuthenticationContext(adalConfig);
export const getToken = () => authContext.getCachedToken(adalConfig.clientId);