import React from 'react';  
import wrench from "./wrench.svg"; 
import './App.css';

export default function NoDataView(props:any) {
    return (
        <>
          <div className="empty-job-wrap" >
             <div className="p-grid j-content-center w-100">
             <div className="p-col-3 LatoFont">            
              <p className="empty-title"> There are no Jobs created.</p>

              {/* <p className="empty-sub-title"> Click here to Add a new Job</p> */}
            </div>

            <div className="p-col-2">
            <div
              className=" LatoFont custom-logo"
              style={{ padding: "15px" }}
            >
              <img
                src={wrench}
                className=""
                style={{
                  marginTop: "30px",
                  marginLeft: "30px",
                  height: "84px",
                }}
              ></img>
            </div>
            </div>
            
            
          </div>
          </div>
        
        </>
    );
  }