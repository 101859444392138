import ReactDOM from 'react-dom/client';
import ReportManager from './reportmanager';
import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig';
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

 const DO_NOT_LOGIN = false;

runWithAdal(
    authContext,
    () => { 
        require('./indexApp'); 
    },
    DO_NOT_LOGIN
);


