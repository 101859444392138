import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from "axios";
import { FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './App.css';
import {
    MRT_EditActionButtons,
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_Row,
    type MRT_TableOptions,
    useMaterialReactTable,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    type MRT_ColumnOrderState,
} from 'material-react-table';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    MenuItem,
    TextField
} from '@mui/material';
import {
    QueryClient,
    QueryClientProvider,
    keepPreviousData,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { type Job, environment, pdfTypes, schedulerOptions } from './lookupdata';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { alpha, styled } from '@mui/material/styles';
import { Guid } from "guid-typescript";
import  NoDataView from "./NoDataView";
import { TextChange, isPropertySignature } from 'typescript';
import { getToken } from './adalConfig';

const ApiUrl = 'https://integrity-reportingmanager-prod.azurewebsites.net/';
//const ApiUrl = 'https://localhost:57436/';
//const ApiUrl = 'https://ic-feature-00001.azurewebsites.net/';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    // height: 450,  
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    // overflow:'auto' 
};

const btnStyle =
{
    bgcolor: '#1976d2',
    color: 'white'
};

const searchItems = ["Report Id", "Status", "Job Title", "File Name", "To"];

type ReportResult = {
    Job: Array<Job>;
    TotalRowCount: number;
};

const ReportManager = () => {
    const [open, setOpen] = React.useState(false);
    const [openCopyDialog, setOpenCopyDialog] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [openPauseDialog, setOpenPauseDialog] = React.useState(false);
    const [openEditJobDialog, setOpenEditJobDialog] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openRunDialog, setOpenRunDialog] = React.useState(false);
    const [openDiscardDialog, setOpenDiscardDialog] = React.useState(false);

    const [openResumeDialog, setOpenResumeDialog] = React.useState(false);
    const [saveDisabled, setSaveDisabled] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleCopyDialogClose = () => setOpenCopyDialog(false);
    const handleDeleteDialogClose = () => setOpenDeleteDialog(false);
    const handlePauseDialogClose = () => setOpenPauseDialog(false);
    const handleDiscardDialogClose = () => setOpenDiscardDialog(false);
    const handleEditJobDialogClose = () => setOpenEditJobDialog(false);
    const handleRunDialogClose = () => setOpenRunDialog(false);
    const handleResumeDialogClose = () => setOpenResumeDialog(false);

    const [toData, setToData] = React.useState(false);
    const [toDataEmail, setToDataEmail] = React.useState(false);
    const [saveEnabledCreateJob, setSaveEnabledCreateJob] = React.useState(false);
    

    const [job, setJobDetails] = useState<Job>();
    const [id, setId] = useState<string>();
    const [ReportId, setReportId] = useState<string>();
    const [DeleteReportId, setDeleteReportId] = useState<string>();
    const [PauseReportId, setPauseReportId] = useState<string>();
    const [BCC, setBCC] = useState(job?.BCC);
    const [To, setTo] = useState(job?.To);
    const [Body, setBody] = useState(job?.Body);
    const [CC, setCC] = useState(job?.CC);
    const [Subject, setSubject] = useState(job?.Subject);
    const [CronSchedule, setCronSchedule] = useState(job?.CronSchedule);
    const [Environment, setEnvironment] = useState(job?.Environment);
    const [FileName, setFileName] = useState(job?.FileName);
    const [FileType, setFileType] = useState(job?.FileType);
    const [filterType, setFilterType] = useState<string>("Report ID");
    const [SchedulerState, setSchedulerState] = useState(job?.SchedulerState);
    const [From, setFrom] = useState(job?.From);
    const [JobDescription, setJobDescription] = useState(job?.JobDescription);
    const [JobTitle, setJobTitle] = useState(job?.JobTitle);
    const [ReportSQL, setReportSQL] = useState(job?.ReportSQL);
    const [EventStatus, setEventStatus] = useState<string>();
    const [searchText, setSearchText] = useState<string>('');    
    const [jobApiResult, setJobApiResult] = useState<Job[]>(); 
    const [totalRowCount, settotalRowCount] = useState<number>();

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
      
    //const [selectedSearchValue, setSelectedSearchValue] = useState<string>();

    const [reportResult, setReportResult] = useState<ReportResult>();    
    const [selectedRowId, setSelectedRowId] = useState('');
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    );
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize:20,
    });

    //if using dynamic columns that are loaded after table instance creation, we will need to manage the column order state ourselves
    const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([]);
    const [validationErrors, setValidationErrors] = useState<
        Record<string, string | undefined>
    >({});

    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpenCopyDialog(false);
    };

    const handleClickCopyDialogOpen = () => {
        setOpenCopyDialog(true);
    };

    const handleClickDeleteDialogOpen = () => {
        setOpenDeleteDialog(true);
    };

    const handleClickPauseDialogOpen = () => {
        setOpenPauseDialog(true);
    };

        const handleClickPauseDialogClose = () => {
        setOpenPauseDialog(false);
    };

    const handleClickRunDialogOpen = () => {
        setOpenRunDialog(true);
    };

    const handleClickResumeDialogOpen = () => {
        setOpenResumeDialog(true);
    };

    const handleClickCopyDialogClose = () => {
        setOpenCopyDialog(false);
    };

    const handleClickDiscardDialogOpen = () => {
        setOpenDiscardDialog(true);
    };

    const validateEmail = (email: string) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      };

    const handleClickSaveButton = () => {
          
        if (From ? !validateEmail(From ? From : "") : false) {
            alert("Invalid 'From' email address");
            return false;
        }       
        else  {
            onSubmitClick();
            window.location.reload();
            return true;
        }
     };

    //READ hook (get jobs from api)
    const useGetJob = () => {
        return UseGetJobDetails();       
    }

    const UseGetJobDetails = () => {
        return useQuery<ReportResult>({
            queryKey: [
                'table-data',
                // columnFilters, //refetch when columnFilters changes
                // globalFilter, //refetch when globalFilter changes
                pagination.pageIndex, //refetch when pagination.pageIndex changes
                pagination.pageSize, //refetch when pagination.pageSize changes
                searchText, //refetch when globalFilter changes
                // sorting, //refetch when sorting changes
            ],
            queryFn: async () => {

                   await new Promise((resolve) => setTimeout(resolve, 1000)); //simulate network delay
                                                         
                const fetchURL = new URL(ApiUrl+"api/prm");
                        //read our state and pass it to the API as query params
                        fetchURL.searchParams.set(
                            'page',
                            `${pagination.pageIndex * pagination.pageSize}`,
                        );
                        fetchURL.searchParams.set('pageSize', `${pagination.pageSize}`);
                        // fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
                        if(searchText != '')
                        {
                            const searchFilters = filterType + "~" + searchText;
                            fetchURL.searchParams.set('filters', searchFilters );
                        }
                        else
                        {
                            fetchURL.searchParams.set('filters',  "" );
                        }
                    
                        // fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));                        
                        const response = await fetch(fetchURL.href, {
                            method:'get',
                            headers: new Headers({
                                'Authorization': 'Bearer '+ getToken(), 
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }),
                        });
                        const json = (await response.json()) as ReportResult;
                        setReportResult(json);                                           
                
                    return reportResult as ReportResult;         
             
            },
            placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
        });
    }  

    let options = { 
      rowStyle: (rowData:any) => {
        return { 
          backgroundColor: rowData.TriggerPaused ? "#FFF5E4": "#fff"
        };
      }, 
    };
  
    const columns = useMemo<MRT_ColumnDef<Job>[]>(
        () => [
            {
                accessorKey: 'ReportID',
                header: 'Report ID',
                enableEditing: false,
                size: 240,
                enableSorting: false,
            },
            {
                accessorKey: 'TriggerPaused',
                header: 'Status',
                enableEditing: false,
                size: 70,
                enableSorting: false,
                // Header: ({ column }) => (<i style={{ color: 'red' }}>{column.columnDef.header}</i>),
                Cell: ({ cell }) => (<span className={cell.getValue<string>() == 'True' ? 'paused-tr' : '' }>
                    {
                        cell.getValue<string>() == 'True' ? 'Paused' : 'Active'
                    }
                </span>)
            },
            {
                accessorKey: 'JobTitle',
                header: 'Job Title',
                size: 180,
                enableSorting: false,
                muiEditTextFieldProps: {
                    type: 'JobTitle',
                    required: true,
                    error: !!validationErrors?.JobTitle,
                    helperText: validationErrors?.JobTitle,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            JobTitle: undefined,
                        }),
                    //optionally add validation checking for onBlur or onChange
                },
            }, 
            {
                accessorKey: 'FileName',
                header: 'File Name',
                size: 180,
                enableSorting: false,
                muiEditTextFieldProps: {
                    type: 'FileName',
                    required: true,
                    error: !!validationErrors?.FileName,
                    helperText: validationErrors?.FileName,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            FileName: undefined,
                        }),
                    //optionally add validation checking for onBlur or onChange
                },
            },           
            {
                accessorKey: 'UserEmail',
                header: 'CreatedBy',
                size: 200,
                enableSorting: false
            },
            {
                accessorKey: 'CronSchedule',
                header: 'Cron Schedule',
                size: 140,
                enableSorting: false,
                muiEditTextFieldProps: {
                    type: 'CronSchedule',
                    required: true,
                    error: !!validationErrors?.CronSchedule,
                    helperText: validationErrors?.CronSchedule,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            CronSchedule: undefined,
                        }),
                    //optionally add validation checking for onBlur or onChange
                },
            },
            {
                accessorKey: 'To',
                header: 'To',
                size: 180,
                enableSorting: false,
                muiEditTextFieldProps: {
                    type: 'To',
                    required: true,
                    error: !!validationErrors?.To,
                    helperText: validationErrors?.To,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            To: undefined,
                        }),
                    //optionally add validation checking for onBlur or onChange
                },
            },
            {
                accessorKey: 'Subject',
                header: 'Subject',
                size: 170,
                enableSorting: false,
                muiEditTextFieldProps: {
                    type: 'Subject',
                    required: true,
                    error: !!validationErrors?.Subject,
                    helperText: validationErrors?.Subject,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            Subject: undefined,
                        }),
                    //optionally add validation checking for onBlur or onChange
                },
            },
            // {
            //     accessorKey: 'FileType',
            //     header: 'File Type',
            //     editVariant: 'select',
            //     size: 60,
            //     editSelectOptions: pdfTypes,
            //     muiEditTextFieldProps: {
            //         select: true,
            //         error: !!validationErrors?.FileType,
            //         helperText: validationErrors?.FileType,
            //         onFocus: () =>
            //             setValidationErrors({
            //                 ...validationErrors,
            //                 FileType: undefined,
            //             }),
            //     },
            // },
            {
                accessorKey: 'JobDescription',
                header: 'Job Description',
                muiEditTextFieldProps: {
                    type: 'JobDescription',
                    required: true,
                    error: !!validationErrors?.JobDescription,
                    helperText: validationErrors?.JobDescription,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            JobDescription: undefined,
                        }),
                    //optionally add validation checking for onBlur or onChange
                },
            },
            {
                accessorKey: 'ReportSQL',
                header: 'Report SQL'
            },
            {
                accessorKey: 'From',
                header: 'From',
                muiEditTextFieldProps: {
                    type: 'From',
                    required: true,
                    error: !!validationErrors?.From,
                    helperText: validationErrors?.From,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            From: undefined,
                        }),
                    //optionally add validation checking for onBlur or onChange
                },
            },
            {
                accessorKey: 'CC',
                header: 'CC',
            },
            {
                accessorKey: 'BCC',
                header: 'BCC',
            },
            {
                accessorKey: 'Body',
                header: 'Body',
                muiEditTextFieldProps: {
                    type: 'Body',
                    required: true,
                    error: !!validationErrors?.Body,
                    helperText: validationErrors?.Body,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            Body: undefined,
                        }),
                    //optionally add validation checking for onBlur or onChange
                },
            },
            {
                accessorKey: 'SchedulerState',
                header: 'Scheduler State',
                editVariant: 'select',
                editSelectOptions: schedulerOptions,
                muiEditTextFieldProps: {
                    select: true,
                    error: !!validationErrors?.SchedulerState,
                    helperText: validationErrors?.SchedulerState,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            SchedulerState: undefined,
                        }),
                }
            },
            {
                accessorKey: 'Environment',
                header: 'Environment',
                editVariant: 'select',
                editSelectOptions: environment,
                muiEditTextFieldProps: {
                    select: true,
                    error: !!validationErrors?.Environment,
                    helperText: validationErrors?.Environment,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            Environment: undefined,
                        }),
                },
            }
        ],
        [validationErrors],
    );
    // const rowOptions = useMemo<MRT_TableOptions<Job>>(
    //     (rowData:any) =>   ({ 
        
    //     options:{
    //       rowStyle: (rowData:any) => ({ 
    //         backgroundColor: 
    //           rowData.TriggerPaused == "True"
    //             ? "orange"
    //             : "#fff"
    //           }) 
    //       }
    //       }) 
    // );

    //call CREATE hook
    const { mutateAsync: createJob, isPending: isCreatingUser } = useCreateJob();
    //call READ hook
    // const {
    //     data: { Job = [], TotalRowCount } = {},
    //     //data: fetchedJobs = [] ,
    //     isError: isLoadingUsersError,
    //     isFetching: isFetchingUsers,
    //     isLoading: isLoadingUsers,
    // } = useGetJob();
    //call UPDATE hook
    const { mutateAsync: updateJob, isPending: isUpdatingUser } = useUpdateJob();
    //call DELETE hook
    const { mutateAsync: deleteJob, isPending: isDeletingUser } = useDeleteJob();

    //CREATE action
    const handleCreateJob: MRT_TableOptions<Job>['onCreatingRowSave'] = async ({
        values,
        table,
    }) => {
        const newValidationErrors = validateUser(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }
        setValidationErrors({});
        await createJob(values);
        table.setCreatingRow(null); //exit creating mode
    };

    //UPDATE action
    const handleSaveJob: MRT_TableOptions<Job>['onEditingRowSave'] = async ({
        values,
        table,
    }) => {
        const newValidationErrors = validateUser(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }
        setValidationErrors({});
        await updateJob(values);
        table.setEditingRow(null); //exit editing mode
    };

    const onRunButtonClick = (row: MRT_Row<Job>) => {
        setSelectedRowId(row.original.ReportID); 
        handleClickRunDialogOpen();
    };

    const runJob =async () => {
        let config  = {
            headers : {
                'Authorization': 'Bearer '+ getToken(), 
                'Content-Type': 'application/json;charset=UTF-8'
             }
        };
        await axios.put(ApiUrl+"api/prm/run/" + selectedRowId, null, config);
        window.location.reload();        
    };

    const onResumeButtonClick = (row: MRT_Row<Job>) => { 
        setSelectedRowId(row.original.ReportID); 
        handleClickResumeDialogOpen();
    };

    const resumeJob =async () => {
        let config  = {
            headers : {
                'Authorization': 'Bearer '+ getToken(), 
                'Content-Type': 'application/json;charset=UTF-8'
             }
        };
        await axios.put(ApiUrl + 'api/prm/resume/' + selectedRowId, null, config);
            window.location.reload();
    };

    const openPauseConfirmModal = (row: MRT_Row<Job>) => {
        setPauseReportId(row.original.ReportID);
        handleClickPauseDialogOpen();
    };

    const onCopyButtonClick = (row: MRT_Row<Job>, event: any) => {        
        setReportId(row.original.ReportID);
        setReportSQL(row.original.ReportSQL);
        setTo(row.original.To);
        setFrom(row.original.From);
        setCC(row.original.CC);
        setBCC(row.original.BCC);
        setSubject(row.original.Subject);
        setBody(row.original.Body);
        setFileName(row.original.FileName);
        setFileType(row.original.FileType);
        setSchedulerState(row.original.SchedulerState);
        setJobTitle(row.original.JobTitle);
        setJobDescription(row.original.JobDescription);
        setCronSchedule(row.original.CronSchedule);
        setEnvironment(row.original.Environment);

        if (event) {
            setReportId(row.original.ReportID);
            setEventStatus('Edit Job');
        }
        else {

            setReportId(Guid.create().toString());
            setEventStatus('Copy Job');
        }

        handleClickCopyDialogOpen();
    };

    const handleEnvironmentChange = (event: SelectChangeEvent) => {
        setEnvironment(event.target.value as string);
    };

    const handleSchedulerStateChange = (event: SelectChangeEvent) => {
        setSchedulerState(event.target.value as string);
    };

    const handleFileTypeChange = (event: SelectChangeEvent) => {       
        setFileType(event.target.value as string);                
    };

    const handleFilterTypeChange = (event: SelectChangeEvent) => {     
        setFilterType(event.target.value as string);
        if(searchText.length > 0)           
        {
            setSearchText('');   
            refreshData();
        }
    };    

    const getData = async () => {
    setIsLoading(true);  
    //await new Promise((resolve) => setTimeout(resolve, 1000)); //simulate network delay                                     
    const fetchURL = new URL(ApiUrl + "api/prm");
    //read our state and pass it to the API as query params
    fetchURL.searchParams.set(
        'page',
        `${pagination.pageIndex * pagination.pageSize}`,
    );
    fetchURL.searchParams.set('pageSize', `${pagination.pageSize}`);
    // fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
    if(searchText != '')
    {
        const searchFilters = filterType + "~" + searchText;
        fetchURL.searchParams.set('filters', searchFilters );
    }
    else
    {
        fetchURL.searchParams.set('filters',  "" );
    }

    try {
        const response = await fetch(fetchURL.href, {
            method:'get',
            headers: new Headers({
                'Authorization': 'Bearer '+ getToken(), 
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
        });
        const json = (await response.json()) as ReportResult;
        //setData(json.data);
        //setRowCount(json.meta.totalRowCount);            
        setJobApiResult(json.Job);
        settotalRowCount(json.TotalRowCount);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    }

    const refreshData = async () => {             
        setIsLoading(true); 
        //await new Promise((resolve) => setTimeout(resolve, 1000)); //simulate network delay                                            
        const fetchURL = new URL(ApiUrl + "api/prm");
        //read our state and pass it to the API as query params
        fetchURL.searchParams.set(
            'page',
            `${pagination.pageIndex * pagination.pageSize}`,
        );
        fetchURL.searchParams.set('pageSize', `${pagination.pageSize}`);
                
        fetchURL.searchParams.set('filters',  "" );                   

        try {            
            const response = await fetch(fetchURL.href, {
                method:'get',
                headers: new Headers({
                    'Authorization': 'Bearer '+ getToken(), 
                    'Content-Type': 'application/x-www-form-urlencoded'
                }),
            });
            const json = (await response.json()) as ReportResult;
            //setData(json.data);
            //setRowCount(json.meta.totalRowCount);            
            setJobApiResult(json.Job);
            settotalRowCount(json.TotalRowCount);
          } catch (error) {
            setIsError(true);
            console.error(error);
            return;
          }
          setIsError(false);
          setIsLoading(false);
          setIsRefetching(false); 
        }

        const goBackbtn = async () => {
            handleDiscardDialogClose();             
            handleClose();
            setOpenCopyDialog(false);
            }


    const handleSearchChangeClick = (event: any) => {        
        if(searchText && searchText.length > 0) {            
            //setSearchText(searchText); 
            getData();             
        }
        else{
            setSaveDisabled(false);
        }        
    }
    
    const handleSearchChangeText = (event: any) => 
    { 
        if (event.target.value  && event.target.value != "")
        {
            setSearchText(event.target.value);
            setSaveDisabled(false);  
        }
        else
        {
            setSearchText('');
            setSaveDisabled(true);
            //refreshData();  
        }     
    };

    const onCopySubmitClick = async (event: React.FormEvent<HTMLFormElement>) => {       
        const job = {
            'ReportID': ReportId,
            'ReportSQL': ReportSQL,
            'To': To,
            'From': From,
            'CC': CC,
            'BCC': BCC,
            'Subject': Subject,
            'Body': Body,
            'FileName': FileName,
            'FileType': FileType,
            'SchedulerState': SchedulerState,
            'JobTitle': JobTitle,
            'JobDescription': JobDescription,
            'CronSchedule': CronSchedule,
            'Environment': (Environment == undefined) ? '' : Environment,
            'EventStatus': EventStatus == 'Edit Job' ? 'Edit' : 'Copy'
        };

        event.preventDefault();
        let config  = {
            headers : {
                'Authorization': 'Bearer '+ getToken(), 
                'Content-Type': 'application/json;charset=UTF-8'
             }
        };
        const response = await axios.post(
            ApiUrl + 'api/prm/', job, config)
            .then(function (response) {
                console.log(response.data);
                handleClose();
            })
            .catch(function (error) {
                console.log(error);
            });
         window.location.reload();
    };   

    const validateMandatoryFields = async() => { 
            if(To && From && CronSchedule && JobTitle && FileName && Subject && ReportSQL && FileType &&
                Environment){
                setSaveEnabledCreateJob(true);
            }
            else{
                setSaveEnabledCreateJob(false);
            }       
    }

    const onSubmitClick = async () => {
            await validateMandatoryFields();
            if(saveEnabledCreateJob) {
            const job = {
                'ReportId': ReportId,
                'To': To,
                'From': From,
                'CC': CC,
                'BCC': BCC,
                'Subject': Subject,
                'Body': Body,
                'FileName': FileName,
                'FileType': FileType,
                'SchedulerState': SchedulerState,
                'JobTitle': JobTitle,
                'JobDescription': JobDescription,
                'CronSchedule': CronSchedule,
                'Environment': (Environment == undefined) ? '' : Environment,
                'EventStatus': 'Create',
                'ReportSQL': ReportSQL,
            };   

            let config  = {
                headers : {
                    'Authorization': 'Bearer '+ getToken(), 
                    'Content-Type': 'application/json;charset=UTF-8'
                 }
             };
           
            const response = await axios.post(
                ApiUrl + 'api/prm/', job , config)
                .then(function (response) {
                    console.log(response.data);
                    handleClose();
                })
                .catch(function (error) {
                    console.log(error);
                });
    
            window.location.reload(); 
    }        
    };

    function onDelete(){ 
        let config  = {
            headers : {
                'Authorization': 'Bearer '+ getToken(), 
                'Content-Type': 'application/json;charset=UTF-8'
             }
        };

        axios.delete(
            ApiUrl + 'api/prm/' + DeleteReportId, config);
            setOpenDeleteDialog(false);
            window.location.reload();
    }

    const onPause = async()=>{        
        let config  = {
            headers : {
                'Authorization': 'Bearer '+ getToken(), 
                'Content-Type': 'application/json;charset=UTF-8'
             }
        };
        await axios.put(ApiUrl + 'api/prm/pause/' + PauseReportId, null, config);
            // setOpenPauseDialog(false);
            window.location.reload();
    }

    //DELETE action
    const openDeleteConfirmModal = (row: MRT_Row<Job>) => {
        setDeleteReportId(row.original.ReportID);
        handleClickDeleteDialogOpen();
    };

    // useEffect(() => {         
    //     var data =  getData();        
    //     validateMandatoryFields();
        
    // }, [To  ,  From  ,  CronSchedule  ,  JobTitle  ,  JobDescription  ,  FileName  ,  Body  ,  Subject  ,  ReportSQL]);

    useEffect(() => {
        
        validateMandatoryFields();
        const fetchData = async () => {
          if (jobApiResult != undefined && jobApiResult.length > 0) {
            setIsLoading(true);
          } else {
            setIsRefetching(true);
          }
    
    const fetchURL = new URL(ApiUrl + "api/prm");
    //read our state and pass it to the API as query params
    fetchURL.searchParams.set(
        'page',
        `${pagination.pageIndex * pagination.pageSize}`,
    );
    fetchURL.searchParams.set('pageSize', `${pagination.pageSize}`);
    // fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
    if(searchText != '')
    {
        const searchFilters = filterType + "~" + searchText;
        fetchURL.searchParams.set('filters', searchFilters );
    }
    else
    {
        fetchURL.searchParams.set('filters',  "" );
    }
    
          try {                        
            const response = await fetch(fetchURL.href, {
                method:'get',
                headers: new Headers({
                    'Authorization': 'Bearer '+ getToken(), 
                    'Content-Type': 'application/x-www-form-urlencoded'
                }),
            });
            const json = (await response.json()) as ReportResult;
            //setData(json.data);
            //setRowCount(json.meta.totalRowCount);            
            setJobApiResult(json.Job);
            settotalRowCount(json.TotalRowCount);
          } catch (error) {
            setIsError(true);
            console.error(error);
            return;
          }
          setIsError(false);
          setIsLoading(false);
          setIsRefetching(false);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [       
        pagination.pageIndex, //re-fetch when page index changes
        pagination.pageSize, //re-fetch when page size changes 
        To,
        From,
        CronSchedule,
        JobTitle,
        JobDescription,
        FileName,
        Body,
        Subject,
        ReportSQL
      ]);
    

    const table = useMaterialReactTable({
        columns,
        // options,
        //data: Job || [],
        data: jobApiResult || [],
        paginationDisplayMode: 'pages',
        enableGlobalFilter: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableColumnFilterModes: false,       
        initialState:
        {
            columnVisibility:
            {
                ReportSQL: false,
                Environment: false,
                ReportServer: false,
                ReportPath: false,
                ReportTitle: false,
                ReportSubTitle: false,
                ReportHeader: false,
                ReportFooter: false,
                FileName: false,
                From: false,
                CC: false,
                BCC: false,
                Body: false,
                JobDescription: false,
                SchedulerState: false
            }
        },
        muiTableHeadCellProps: {
            sx: {
                '& .Mui-TableHeadCell-Content': {
                    justifyContent: 'space-between',
                },
            },
        },
        muiCircularProgressProps:{
            color: 'secondary',
            thickness: 5,
            size: 55,            
        },
        muiSkeletonProps:{
            animation: 'pulse',
            height: 28,
        },
        createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
        editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)    
        getRowId: (row) => row.ReportID, 
        manualFiltering: true, //turn off built-in client-side filtering
        manualPagination: true, //turn off built-in client-side pagination
        manualSorting: true, //turn off built-in client-side sorting
        //onColumnFiltersChange: setColumnFilters,
        onColumnOrderChange: setColumnOrder,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        //onSortingChange: setSorting,
        rowCount: totalRowCount ?? 0,
        enableColumnActions: false,
        // paginationDisplayMode: 'pages',
        muiPaginationProps: {
            rowsPerPageOptions: [20, 50, 100]
        },
        muiToolbarAlertBannerProps: false
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        muiTablePaperProps: {
            elevation: 0, //change the mui box shadow
            //customize paper styles
            sx: {
                borderRadius: '0',
                border: '1px dashed #e0e0e0',
                minWidth: '1000px'
            },
        },
        onCreatingRowCancel: () => setValidationErrors({}),
        onCreatingRowSave: handleCreateJob,
        onEditingRowCancel: () => setValidationErrors({}),
        onEditingRowSave: handleSaveJob,
        enableRowActions: true,
        positionActionsColumn: 'last',
        enableHiding: false,        
        renderRowActionMenuItems: ({ row }) => [
            <MenuItem key="run" onClick={() => onRunButtonClick(row)} className='multi-menu run-icon'>
                Run
            </MenuItem>,
            <MenuItem key="edit" onClick={() => onCopyButtonClick(row, true)} className='multi-menu edit-icon'>
                Edit
            </MenuItem>,
            <MenuItem key="copy" onClick={() => onCopyButtonClick(row, false)} className='multi-menu copy-icon'>
                Copy
            </MenuItem>,
            <MenuItem key="resume" onClick={() => onResumeButtonClick(row)} className='multi-menu resume-icon'>
                Resume
            </MenuItem>,
            <MenuItem key="pause" onClick={() => {openPauseConfirmModal(row)}} className='multi-menu pause-icon'>
                Pause
            </MenuItem>,
            <MenuItem key="delete" onClick={() => {openDeleteConfirmModal(row)}} className='multi-menu delete-icon'>
                Delete
            </MenuItem>,
        ],
        state: {
            // columnFilters,
            // columnOrder,
            // globalFilter,
            pagination,
            isLoading: isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
        },
    });

    return (
        <div>
    <div className="prm-container">
      <div className="top-header">   
      <div className="p-grid"> 
      <div className="p-col-6 pb-0">
      <div  className="logo-layout" > </div>
      </div>
      <div className="p-col-6 pb-0 text-right">
        &nbsp;
      </div>
      </div>   
      </div> 
      <div className="app-title">
      Integrity Reporting Manager
      </div>

      <div className="add-search-container">       
        <div className="p-grid">
        <div className="p-col-8 pb-0"> 
      <div className="search-wrap">                
        <Select id='ddlFilter'                                                        
            value={FileType}
            onChange={handleFilterTypeChange}
            defaultValue="Report Id"
            >
            {
                searchItems.map(searchItems => {
                    return <MenuItem value={searchItems} selected = {searchItems == "Report Id" ? true : false}>{searchItems}</MenuItem>
                })
            }
        </Select>

        <div className="text-field">       
            <TextField id="txtSearch" type="text" className="form-control" name="searchText"
            variant="standard" value={searchText} onChange={(event) => {handleSearchChangeText(event)} } />         
        </div>
     </div> 
        <div className='save-btn-wrap'>
            <Button variant="contained" 
            onClick = {(event) => {handleSearchChangeClick(event)}}        
            disabled={saveDisabled}>Search</Button>
        </div>

    </div>
       
        <div className="p-col-4 pb-0 add-btn-wrap"> 
        <Button
          className="add-job-btn"
            variant="contained"
            onClick={() => {
                setReportSQL('');
                setTo('');
                setFrom('');
                setCC('');
                setBCC('');
                setSubject('');
                setBody('');
                setFileName('');
                setFileType('');
                setSchedulerState('');
                setJobTitle('');
                setJobDescription('');
                setCronSchedule('');
                setEnvironment('');
                handleOpen();
                setReportId(Guid.create().toString());
            }}>
            Add Job
        </Button>                       

           </div>
           </div>
           </div>
           

        { jobApiResult != undefined && jobApiResult.length > 0 ? <MaterialReactTable table={table} />:
        <NoDataView />}
        <div className='modalWrapper'>
            <div>
                <Modal
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="create-job-modal"
                    >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            <div className="title-page-wrap">                      
                            <i  className="back-icon"  aria-hidden="true"
                        onClick={() => {handleClickDiscardDialogOpen()}}                        
                        ></i>  
                        <div className="title-modal">Create Job</div> 
                        </div>  

                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <form>                            

                              <div className="custom-form-wrapper">
                              <div className="p-grid">
                              <div className="p-col-8">

                              <div className="p-grid">
                              <div className="p-col-4">
                              <div className="form-group req-wrap">
                                                    <TextField id="txtReportId" type="text" className="form-control" name="Report Id"
                                                        label="Report Id" variant="standard"
                                                        value={ReportId}
                                                         />
                                                </div>
                                        </div>

                             <div className="p-col-4">
                             <div className="form-group req-wrap">
                                                    {
                                                       <TextField id="txtTo" type="text" className="form-control" name="To"
                                                       label="To" variant="standard"
                                                       value={To}
                                                       onChange={(e) => {setTo(e.target.value) }}
                                                      /> 
                                                    }
                                                    {toDataEmail ? (
                                                        <div className="invalid-feedback invalid-error">
                                                        Email  is already in use on this
                                                        contract
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                        </div>
                            <div className="p-col-4">
                            <div className="form-group req-wrap">
                                                    <TextField id='txtFrom' type="text" className="form-control" name="From"
                                                        label="From" variant="standard"
                                                        value={From}                                                       
                                                        onChange={(e) => {setFrom(e.target.value); validateMandatoryFields(); }}
                                                        />
                                                </div>
                                        </div>
                                        </div>

                                        <div className="p-grid">
                                        <div className="p-col-4">
                                        <div className="form-group">
                                                    <TextField id="txtCC" type="text" className="form-control" name="CC"
                                                        label="CC" variant="standard"
                                                        value={CC}
                                                        onChange={(e) => {setCC(e.target.value);}} />
                                                </div>
                                        </div>

                              <div className="p-col-4">
                              <div className="form-group">
                                                    <TextField id="txtName" type="text" className="form-control" name="name"
                                                        label="BCC" variant="standard"
                                                        value={BCC}
                                                        onChange={(e) => setBCC(e.target.value)}
                                                        />
                                                </div>
                                </div>

                                <div className="p-col-4">
                                <div className="form-group req-wrap">
                                                    <TextField id="txtSubject" type="text" className="form-control" name="Subject"
                                                        label="Subject" variant="standard"
                                                        value={Subject}
                                                        onChange={(e) => {setSubject(e.target.value) }} />
                                                </div>
                                </div>                                
                                </div>

                                    <div className="p-grid">
                                    <div className="p-col-4">
                                <div className="form-group">
                                                    <TextField id="txtBody" type="text" className="form-control" name="Body"
                                                        label="Body" variant="standard"
                                                        value={Body}
                                                        onChange={(e) => {setBody(e.target.value) }} />
                                                </div>
                                </div>

                                <div className="p-col-4">
                                <div className="form-group req-wrap">
                                                    <TextField id="txtFileName" type="text" className="form-control" name="FileName"
                                                        label="File Name" variant="standard"
                                                        value={FileName}
                                                        onChange={(e) => {setFileName(e.target.value) }} />
                                                </div>
                                </div>

                                <div className="p-col-4">
                              <div className="form-group req-wrap">
                                                    <InputLabel id="lblFileTypes">File Type</InputLabel>
                                                    <Select id='ddlPdfTypes'
                                                        value={FileType}
                                                        onChange={handleFileTypeChange}
                                                        >
                                                        {
                                                            pdfTypes.map(pdfTypes => {
                                                                return <MenuItem value={pdfTypes}>{pdfTypes}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </div>

                                </div>

                            </div>

                                <div className="p-grid">                         

                                <div className="p-col-4">
                                <div className="form-group req-wrap">
                                                    <InputLabel id="lblSchedulerType">Scheduler Type</InputLabel>
                                                    <Select
                                                        value={SchedulerState}
                                                        onChange={handleSchedulerStateChange} >
                                                        {
                                                            schedulerOptions.map(schedulerOptions => {
                                                                return <MenuItem value={schedulerOptions}>{schedulerOptions}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </div>

                                </div>

                                <div className="p-col-4">
                                <div className="form-group req-wrap">
                                                    <TextField id="txtJobTitle" type="text" className="form-control" name="JobTitle"
                                                        label="Job Title" variant="standard"
                                                        value={JobTitle}
                                                        onChange={(e) => {setJobTitle(e.target.value);  }} />
                                                </div>

                                </div>

                                <div className="p-col-4">
                                <div className="form-group">
                                                    <TextField id="txtJobDescription" type="text" className="form-control" name="JobDescription"
                                                        label="Job Description" variant="standard" value={JobDescription}
                                                        onChange={(e) => {setJobDescription(e.target.value);  }}
                                                        />
                                                </div>

                                </div>
                                </div>

                                <div className="p-grid">
                              <div className="p-col-4">
                              <div className="form-group req-wrap">
                                                    <TextField id="txtCronSchedule" type="text" className="form-control" name="CronSchedule"
                                                       label="Cron Schedule" variant="standard"
                                                        value={CronSchedule}
                                                        onChange={(e) => {setCronSchedule(e.target.value) }} />
                                                </div>
                                </div>

                                <div className="p-col-4">
                                <div className="form-group req-wrap">
                                                    <InputLabel id="lblEnvironment">Environment</InputLabel>
                                                    <Select onChange={handleEnvironmentChange}
                                                       value={Environment}>
                                                        {
                                                            environment.map(allenv => {
                                                                return <MenuItem value={allenv}>{allenv}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </div>
                                </div>
                                    </div>
                                </div>

                                <div className="p-col-4">
                                <div className="form-group rep-sql-wrap req-wrap mt-15">
                                                        <TextField id='txtReportSQL'
                                                            label="Report SQL"
                                                            value={ReportSQL}
                                                            onChange={(e) => {setReportSQL(e.target.value);  validateMandatoryFields();}}
                                                            multiline
                                                            rows={20}
                                                            style={{ width: "97%" }} />
                                                    </div>
                                </div>
                                </div>                              
                                </div>

                                <div className='modalFooter-create-job'>
                                  <div>
                                      <Button onClick={() => handleClickDiscardDialogOpen()}>Cancel</Button>

                                      <div className='d-inline-block'>                                    
                       
                              <div className="tooltip-irm-save"><Button variant="contained" disabled={!saveEnabledCreateJob} 
                              onClick={() => handleClickSaveButton()}
                              >Save</Button>
                            <span 
                             style={{                               
                                display: saveEnabledCreateJob ? "none" : "block",
                               
                              }}
                            className="tooltiptext-irm"> This button is disabled because a required field is
                          missing information.</span>
                            </div>   </div>
                                  </div>
                              </div>
                                
                            </form>
                        </Typography>
                    </Box>
                </Modal>
            </div>
        </div>

        <div>
            <Modal
                open={openCopyDialog}
                // onClose={handleClickCopyDialogClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="create-job-modal">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    <div className="title-page-wrap"> 
                    <i  className="back-icon"  aria-hidden="true"
                        onClick={() => {handleClickDiscardDialogOpen()}} 
                        ></i>  
                        <div className="title-modal">
                        {
                            EventStatus
                        }
                        </div> </div>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <form onSubmit={onCopySubmitClick}>                                           

                                            <div className="custom-form-wrapper">
                                            <div className="p-grid">
                                            <div className="p-col-8">
                                                    <div className="p-grid">

                                                    <div className="p-col-4">
                                                    <div className="form-group req-wrap">
                                                <TextField id="txtReportId" type="text" className="form-control" name="Report Id"
                                                    label="Report Id" variant="standard"                                                    
                                                    value={ReportId}
                                                    />
                                            </div>
                                                        </div>

                                                        <div className="p-col-4">
                                                        <div className="form-group req-wrap">
                                                {
                                                    <TextField id="txtTo" type="text" className="form-control" name="To"
                                                        label="To" variant="standard"                                                        
                                                        value={To}
                                                        onChange={(e) => {setTo(e.target.value) }}
                                                        />
                                                }
                                            </div>
                                                        </div>

                                                        <div className="p-col-4">
                                                        <div className="form-group req-wrap">
                                                <TextField id='txtFrom' type="text" className="form-control" name="From"
                                                    
                                                    label="From" variant="standard"
                                                    value={From}
                                                    onChange={(e) => {setFrom(e.target.value); validateMandatoryFields(); }}
                                                    />
                                            </div>
                                                        </div>

                                                      
                                              </div>

                                              <div className="p-grid">
                                              <div className="p-col-4">
                                                        <div className="form-group">
                                                <TextField id="txtCC" type="text" className="form-control" name="CC"
                                                    label="CC" variant="standard"
                                                    value={CC}
                                                    onChange={(e) => setCC(e.target.value)} />
                                            </div>
                                             </div>

                                              <div className="p-col-4">
                                              <div className="form-group">
                                                <TextField id="txtName" type="text" className="form-control" name="name"
                                                    label="BCC" variant="standard"
                                                    value={BCC}
                                                    onChange={(e) => setBCC(e.target.value)}
                                                    />
                                            </div>
                                                </div>

                                                <div className="p-col-4">
                                                <div className="form-group req-wrap">
                                                <TextField id="txtSubject" type="text" className="form-control" name="Subject"
                                                    
                                                    label="Subject" variant="standard"
                                                    value={Subject}
                                                    onChange={(e) => {setSubject(e.target.value) }}
                                                    />
                                            </div>
                                                </div>

                                                </div>

                                                <div className="p-grid">
                                                    
                                                <div className="p-col-4">
                                                <div className="form-group">
                                                <TextField id="txtBody" type="text" className="form-control" name="Body"
                                                    
                                                    label="Body" variant="standard"
                                                    value={Body}
                                                    onChange={(e) => {setBody(e.target.value) }} />
                                            </div>
                                                </div>

                                                <div className="p-col-4">
                                                <div className="form-group req-wrap">
                                                <TextField id="txtFileName" type="text" className="form-control" name="FileName"
                                                    
                                                    label="File Name" variant="standard"
                                                    value={FileName}
                                                    onChange={(e) => {setFileName(e.target.value) }} />
                                            </div>
                                                </div>

                                                    <div className="p-col-4">
                                                    <div className="form-group req-wrap">
                                                <InputLabel id="lblFileTypes">File Type</InputLabel>
                                                <Select id='ddlPdfTypes'
                                                    
                                                    value={FileType}
                                                    onChange={handleFileTypeChange}
                                                    >
                                                    {
                                                        pdfTypes.map(pdfTypes => {
                                                            return <MenuItem value={pdfTypes}>{pdfTypes}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                                    </div>                                        
                                              </div>

                                              <div className="p-grid">
                                              <div className="p-col-4">
                                                    <div className="form-group req-wrap">
                                                <InputLabel id="lblSchedulerType">Scheduler Type</InputLabel>
                                                <Select
                                                    
                                                    value={SchedulerState}
                                                    onChange={handleSchedulerStateChange} >
                                                    {
                                                        schedulerOptions.map(schedulerOptions => {
                                                            return <MenuItem value={schedulerOptions}>{schedulerOptions}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                                    </div> 
                                                
                                              <div className="p-col-4">
                                                    <div className="form-group req-wrap">
                                                <TextField id="txtJobTitle" type="text" className="form-control" name="JobTitle"
                                                    
                                                    label="Job Title" variant="standard"
                                                    value={JobTitle}
                                                    onChange={(e) => {setJobTitle(e.target.value);  }} />
                                            </div>
                                                    </div>

                                              <div className="p-col-4">
                                                    <div className="form-group">
                                                <TextField id="txtJobDescription" type="text" className="form-control" name="JobDescription"
                                                    
                                                    label="Job Description" variant="standard" value={JobDescription}
                                                    onChange={(e) => {setJobDescription(e.target.value);  validateMandatoryFields();}}
                                                    />
                                            </div>
                                             </div>
                                                </div>

                                              <div className="p-grid">
                                              <div className="p-col-4">
                                              <div className="form-group req-wrap">
                                                <TextField id="txtCronSchedule" type="text" className="form-control" name="CronSchedule"
                                                    
                                                    label="Cron Schedule" variant="standard"
                                                    value={CronSchedule}
                                                    onChange={(e) => {setCronSchedule(e.target.value) }} />
                                            </div>    
                                                </div>

                                                <div className="p-col-4">
                                                <div className="form-group req-wrap">
                                                <InputLabel id="lblEnvironment">Environment</InputLabel>
                                                <Select onChange={handleEnvironmentChange}
                                                    
                                                    value={Environment}>
                                                    {
                                                        environment.map(allenv => {
                                                            return <MenuItem value={allenv}>{allenv}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </div>    
                                                </div>                                                
                                              </div>
                                                </div>

                                                <div className="p-col-4">
                                                <div className="form-group req-wrap rep-sql-wrap mt-15">
                                                    <TextField id='txtReportSQL'
                                                        
                                                        label="Report SQL"
                                                        value={ReportSQL}
                                                        onChange={(e) => {setReportSQL(e.target.value);  validateMandatoryFields();}}
                                                        multiline
                                                        rows={18}
                                                        style={{ width: "97%" }} />
                                                </div>
                                                </div>
                                                </div>

                                                <div className='modalFooter-create-job'>
                                                <div>
                                                    <Button onClick={() => handleClickDiscardDialogOpen()}>Cancel</Button>
                                                    <div className='d-inline-block'>                                   
                       
                                                    <div className="tooltip-irm-save"><Button variant="contained" disabled={!saveEnabledCreateJob}
                                                    onClick={() => handleClickSaveButton()}
                                                    >Save</Button>
                                                    <span 
                                                    style={{                               
                                                        display: saveEnabledCreateJob ? "none" : "block",
                                                        
                                                    }}
                                                    className="tooltiptext-irm"> This button is disabled because a required field is
                                                missing information.</span>
                                                    </div>   </div>

                                                    {/* <Button variant="contained" type='submit' disabled={!saveEnabledCreateJob}>Save</Button> */}
                                                </div>
                                            </div>
                                                </div>


                            {/* <table align='left'>
                                <tbody>
                                    
                                </tbody>
                            </table> */}
                        </form>
                    </Typography>
                </Box>
            </Modal>
        </div>

        
             {/* Delete Modal Start  */}
           
            <div>
                <Modal
                    open={openDeleteDialog}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="custom-modal custom-modal-sm"
                    >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Delete Job
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                        

                              <div className="modal-content-body sm-content">
                              Are you sure you want to delete this job?
                                </div>

                                <div className="modal-footer">
                                  <div>
                                      <Button onClick={() => handleDeleteDialogClose()}>Cancel</Button>
                                      <Button id="delete-btn" variant="contained" onClick={() => onDelete()}>Delete Job</Button>
                                  </div>
                              </div>
                                
                           
                        </Typography>
                    </Box>
                </Modal>
            </div>     

         {/* Delete Modal end*/}

         {/* Pause Modal Start  */}
         <div>
                <Modal
                    open={openPauseDialog}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="custom-modal custom-modal-sm"
                    >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Pause Job
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>                                                   

                              <div className="modal-content-body sm-content">
                              Are you sure you want to pause the job?
                                </div>

                                <div className="modal-footer">
                                  <div>
                                      <Button onClick={() => handlePauseDialogClose()}>Cancel</Button>
                                      <Button variant="contained" onClick={() => onPause()}>Pause Job</Button>
                                  </div>
                              </div>
                        </Typography>
                    </Box>
                </Modal>
            </div> 

         {/* Pause Modal end  */}

         {/* Run Modal Start  */}
         <div>
                <Modal
                    open={openRunDialog}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="custom-modal custom-modal-sm"
                    >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Run Job
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>                                                    

                              <div className="modal-content-body sm-content">
                              Are you sure you want to run the job?
                                </div>

                                <div className="modal-footer">
                                  <div>
                                      <Button onClick={() => handleRunDialogClose()}>Cancel</Button>
                                      <Button variant="contained" onClick={() => runJob()} type='submit'>Run Job</Button>
                                  </div>
                              </div>                                
                            
                        </Typography>
                    </Box>
                </Modal>
            </div> 

         {/* Run Modal end  */}

          {/* Resume Modal Start  */}
          <div>
                <Modal
                    open={openResumeDialog}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="custom-modal custom-modal-sm"
                    >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                        Resume Job
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>                                                      

                              <div className="modal-content-body sm-content">
                              Are you sure you want to resume the job?
                                </div>

                                <div className="modal-footer">
                                  <div>
                                      <Button onClick={() => handleResumeDialogClose()}>Cancel</Button>
                                      <Button variant="contained" onClick={() => resumeJob()}>Resume Job</Button>
                                  </div>
                              </div> 
                        </Typography>
                    </Box>
                </Modal>
            </div> 

         {/* Resume Modal end  */}     

         {/* Discard Modal Start  */}
         <div>
                <Modal
                    open={openDiscardDialog}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="custom-modal custom-modal-sm"
                    >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                        Discard Changes
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>                                                   

                              <div className="modal-content-body sm-content">
                              Are you sure you want to exit and discard all changes?
                                </div>

                                <div className="modal-footer">
                                  <div>
                                      <Button onClick={() => handleDiscardDialogClose()}>Back</Button>
                                      <Button variant="contained" onClick={() => {goBackbtn()}}>Discard Changes</Button>
                                  </div>
                              </div>
                        </Typography>
                    </Box>
                </Modal>
            </div> 

         {/* Discard Modal End  */}
         
    </div> 
    <div className="footer-wrap">
    &copy; 2024 Integrity Reporting Manager. All rights reserved.        
    </div>   
    </div>
    );   


    //CREATE hook (post new job to api)
    function useCreateJob() {
        const queryClient = useQueryClient();
        let config  = {
            headers : {
                'Authorization': 'Bearer '+ getToken(), 
                'Content-Type': 'application/json;charset=UTF-8'
             }
         };

        return useMutation({
            mutationFn: async (job: Job) => {
                const response = await axios.post(
                    ApiUrl + 'api/prm/', job, config);

                const res = await response.data;
                setId(res)
                console.log(res);

                //send api update request here
                //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
                return Promise.resolve(res);
            },
            //client side optimistic update
            onMutate: (newUserInfo: Job) => {
                queryClient.setQueryData(
                    ['users'],
                    (prevUsers: any) =>
                        [
                            ...prevUsers,
                            {
                                ...newUserInfo,
                                ReportID: id,
                            },
                        ] as Job[],
                );
            },
            // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
        });
    }
};

//UPDATE hook (put job in api)
function useUpdateJob() {
    const queryClient = useQueryClient();
    let config  = {
        headers : {
            'Authorization': 'Bearer '+ getToken(), 
            'Content-Type': 'application/json;charset=UTF-8'
         }
    };

    return useMutation({
        mutationFn: async (job: Job) => {
            const response = await axios.post(
                ApiUrl + 'api/prm/', job, config
            );
            const res = await response.data;

            console.log(res);

            //send api update request here
            //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
            return Promise.resolve();
        },
        //client side optimistic update
        onMutate: (newJobInfo: Job) => {
            queryClient.setQueryData(
                ['users'],
                (prevJobs: any) =>
                    prevJobs?.map((prevJob: Job) =>
                        prevJobs.ReportID === newJobInfo.ReportID ? newJobInfo : prevJob,
                    ),
            );
        },
        // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
    });
}

//DELETE hook (delete job in api)

function useDeleteJob() {    
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (ReportID: string) => {
            alert();           
            console.log(ReportID);
           

            //send api update request here
            //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
            return Promise.resolve();
        },
    });
}

const queryClient = new QueryClient();

const ReportManagerWithProviders = () => (
    //Put this with your other react-query providers near root of your app
    <QueryClientProvider client={queryClient}>
        <ReportManager />
    </QueryClientProvider>
);

export default ReportManagerWithProviders;

const validateRequired = (value: string) => !!value.length;
const validateEmail = (email: string) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );

function validateUser(job: Job) {
    return {
        JobTitle: !validateRequired(job.JobTitle)
            ? 'Job Title is Required'
            : '',
        ReportSQL: !validateRequired(job.ReportSQL) ? 'Report SQL is Required' : '',
        To: !validateRequired(job.To) ? 'To is Required' : '',
        From: !validateRequired(job.From) ? 'From is Required' : '',
        FileName: !validateRequired(job.FileName) ? 'File Name is Required' : '',
        Subject: !validateRequired(job.Subject) ? 'Subject is Required' : '',
        Body: !validateRequired(job.Body) ? 'Body is Required' : '',
        JobDescription: !validateRequired(job.JobDescription) ? 'Job Description is Required' : '',
        CronSchedule: !validateRequired(job.CronSchedule) ? 'Cron Schedule Required' : '',
        FileType: !validateRequired(job.FileType) ? 'FileType Required' : '',
        SchedulerState: !validateRequired(job.SchedulerState) ? 'Scheduler State Required' : '',
        Environment: !validateRequired(job.Environment) ? 'Environment Required' : ''
    };
}